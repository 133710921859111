/**
 * File extension checker
 */

export default {
	zip (file) {
		if (/(\.|\/)(x-gzip|zip|x-tar)$/i.test(file.type)) {
			// Test for accepted mime type
			return true;
		} else if (/(\.)(tgz|gz|zip|tar)$/i.test(file.name)) {
			// Test for accepted file extension
			return true;
		}

		return false;
	},

	image (file) {
		if (/(\.|\/)(jpg|jpeg|png)$/i.test(file.type)) {
			return true;
		}

		return false;
	}
};
