/**
 * S3 Uploader
 */

import $ from 'jquery';
import extensionCheck from 'helpers/check-extension';
import notif from 'components/notification';

/* eslint camelcase: 0 */
export default {
	els: {},

	settings: {
		path: '',
		additional_data: null,
		before_add: null,
		remove_completed_progress_bar: true,
		remove_failed_progress_bar: false,
		progress_bar_target: null,
		click_submit_target: null,
		allow_multiple_files: false,
		only_zip: false,
		only_image: false,
		max_size: 2048
	},

	init ($element, options) {
		const self = this;

		this.els.$upload_form = null;
		this.els.$wrapping_form = null;
		this.els.cleaned_filename = null;
		this.els.current_files = null;
		this.els.forms_for_submit = $('#s3_migration_uploader');

		// Run init on each form items passed.
		if ($element.length > 1) {
			$element.each(() => self.init($element, options));

			return self;
		}

		this.els.$upload_form = $element;

		$.extend(self.settings, options);

		this.els.current_files = [];
		this.els.forms_for_submit = [];

		// This is the target that, if there's a change staged, this will make
		// sure the file uploads and propogates to the actual form
		if (this.settings.click_submit_target) {
			this.settings.click_submit_target.click(() => {
				let form;
				let i;
				let len;

				for (i = 0, len = self.els.forms_for_submit.length; i < len; i++) {
					form = self.els.forms_for_submit[i];
					form.submit();
				}

				return false;
			});
		}

		// grabs the wrapping form if the s3 form is nested inside a form.
		this.els.$wrapping_form = this.els.$upload_form.closest('form');

		if (this.els.$wrapping_form.length > 0) {
			this.els.$wrapping_form.off('submit').on('submit', () => {
				self.els.$wrapping_form.find('.s3_uploader input').prop('disabled', true);
				return true;
			});
		}

		this.els.$upload_form.data('key', self.els.$upload_form.find('input[name="key"]').val());

		import(/* webpackChunkName: "blueimp" */ 'blueimp-file-upload').then(() => {
			this.set_upload_form();
		});

		return this;
	},

	// Gets the fixed path
	path_fix (new_path) {
		this.settings.path = new_path;
		return this.settings.path;
	},

	// Gets extra data
	additional_data_fix (new_data) {
		this.settings.additional_data = new_data;
		return this.settings.additional_data;
	},

	// Get the form content
	get_content_object ($upload_form, file, result) {
		const self = this;
		let content = {};
		let domain;
		let key;

		if (result) {
			content.url = $(result).find('Location').text();
			content.filepath = $('<a />').attr('href', content.url)[0].pathname;
		} else {
			domain = self.els.$upload_form.find('input[type=file]').data('url');
			key = self.els.$upload_form.find('input[name=key]').val();
			content.filepath = key.replace('/{filename}', '').replace('/{cleaned_filename}', '');
			content.url = domain + key.replace('/{filename}', encodeURIComponent(file.name));
			content.url = content.url.replace('/{cleaned_filename}', self.cleaned_filename(file.name));
		}

		content.filename = file.name;

		if ('size' in file) {
			content.filesize = file.size;
		}

		if ('lastModifiedDate' in file) {
			content.lastModifiedDate = file.lastModifiedDate;
		}

		if ('type' in file) {
			content.filetype = file.type;
		}

		if ('unique_id' in file) {
			content.unique_id = file.unique_id;
		}

		if (self.has_relative_path(file)) {
			content.relativePath = self.build_relative_path(file);
		}

		if (self.settings.additional_data) {
			content = $.extend(content, self.settings.additional_data);
		}

		return content;
	},

	// Cleans up the file name
	cleaned_filename (filename) {
		return filename.replace(/\s/g, '_').replace(/[^\w.-]/gi, '');
	},

	// detects if we can grab a relative file path
	has_relative_path (file) {
		return file.relativePath || file.webkitRelativePath;
	},

	// creates a relative file path from a file object
	build_relative_path (file) {
		return file.relativePath || (file.webkitRelativePath ? `${file.webkitRelativePath.split('/').slice(0, -1).join('/')}/` : void 0);
	},

	// initializes fileupload on the s3 form's file input tag.
	set_upload_form () {
		const self = this;

		return this.els.$upload_form.find('input[type="file"]').fileupload({
			// Fires whenever a file is added or changed
			add (e, data) {
				const file = data.files[0];

				file.unique_id = Math.random().toString(36).substr(2, 16);

				if (!(self.settings.before_add && !self.settings.before_add(file))) {
					self.els.current_files.push(data);

					data.context = self.settings.progress_bar_target;

					const sizeKb = file.size / 1024;
					const sizeMb = sizeKb / 1000;

					const sizeString = function () {
						if (sizeMb >= 1024) {
							return `${self.settings.max_size / 1024}GB`;
						}

						return `${self.settings.max_size}MB`;
					};

					// If the size is larger than 2GB
					if (sizeMb > self.settings.max_size) {
						notif.show({
							message: `Files must be smaller than ${sizeString()}`,
							type: 'error',
							float: true
						});

						return;
					}

					const wrongFormat = function (type) {
						// Pop a floating notif if it fails
						notif.show({
							message: `You can only upload ${type} files!`,
							type: 'error',
							float: true
						});

						// and trigger the event on the form
						return self.els.$upload_form.trigger('s3_uploads_fail', { message: `Must be a ${type}` });
					};

					// If the file should only be a zip, do a check
					if (self.settings.only_zip) {
						if (!extensionCheck.zip(file)) {
							wrongFormat('Zip');
							return;
						}
					}

					if (self.settings.only_image) {
						if (!extensionCheck.image(file)) {
							wrongFormat('PNG or JPG');
							return;
						}
					}

					// If the submit target for the form is set,
					// you can auto submit the form.
					if (self.settings.click_submit_target) {
						if (self.settings.allow_multiple_files) {
							return self.els.forms_for_submit.push(data);
						}

						self.els.forms_for_submit = [data];

						return self.els.forms_for_submit;

					}

					return data.submit();
				}
			},

			// function that runs when the upload starts
			start (e) {
				return self.els.$upload_form.trigger('s3_uploads_start', [e]);
			},

			// function that runs when a progress event occurs
			progress (e, data) {
				if (self.settings.progress_bar_target) {
					const progress = parseInt(data.loaded / data.total * 100, 10);

					self.settings.progress_bar_target.parent().removeClass('hidden');
					self.settings.progress_bar_target.addClass('--active');
					self.settings.progress_bar_target.find('h2').text(`${progress}%`);

					return self.settings.progress_bar_target.find('.progress-indicator').css('width', `${progress}%`);
				}
			},

			// function that runs when the upload is complete or fails
			done (e, data) {
				const callback_url = self.els.$upload_form.data('callback-url');
				const content = self.get_content_object(self.els.$upload_form, data.files[0], data.result);

				// if a callback url is set on the form (not sure where we use this)
				// basically upload the file multiple places.
				if (callback_url) {
					content[self.els.$upload_form.data('callback-param')] = content.url;

					$.ajax({
						headers: { 'X-CSRF-Token': FLYWHEEL_CSRF_TOKEN },
						type: self.els.$upload_form.data('callback-method'),
						url: callback_url,
						data: content,
						beforeSend (xhr, settings) {
							const event = new $.Event('ajax:beforeSend');

							self.els.$upload_form.trigger(event, [xhr, settings]);

							return event.result;
						},
						complete (xhr, status) {
							const event = new $.Event('ajax:complete');

							self.els.$upload_form.trigger(event, [xhr, status]);

							return event.result;
						},
						success (data, status, xhr) {
							const event = new $.Event('ajax:success');

							self.els.$upload_form.trigger(event, [data, status, xhr]);

							return event.result;
						},
						error (xhr, status, error) {
							const event = new $.Event('ajax:error');

							self.els.$upload_form.trigger(event, [xhr, status, error]);

							return event.result;
						}
					});
				}

				if (data.context && self.settings.remove_completed_progress_bar) {
					data.context.remove();
				}

				// Remove the active class from the progress_bar
				if (self.settings.progress_bar_target) {
					self.settings.progress_bar_target.removeClass('--active');
				}

				// trigger the s3_upload_complete event
				self.els.$upload_form.trigger('s3_upload_complete', [content]);
				self.els.current_files.splice($.inArray(data, self.els.current_files), 1);

				if (!self.els.current_files.length) {
					return self.els.$upload_form.trigger('s3_uploads_complete', [content]);
				}
			},

			// function called when the upload fails
			fail (e, data) {
				const content = self.get_content_object(self.els.$upload_form, data.files[0], data.result);
				content.error_thrown = data.errorThrown;

				if (data.context && self.settings.remove_failed_progress_bar) {
					data.context.remove();
				}

				return self.els.$upload_form.trigger('s3_uploads_failed', [content]);
			},

			// initialize the formData and pass it back.
			formData () {
				const data = self.els.$upload_form.find('input').serializeArray();

				let fileType = '';
				let key = '';
				let key_field = '';

				if ('type' in this.files[0]) {
					fileType = this.files[0].type;
				}

				data.push({
					name: 'content-type',
					value: fileType
				});

				key = self.els.$upload_form.data('key').replace('{timestamp}', new Date().getTime()).replace('{unique_id}', this.files[0].unique_id).replace('{cleaned_filename}', self.cleaned_filename(this.files[0].name)).replace('{extension}', this.files[0].name.split('.').pop());

				Object.defineProperty(this.files[0], 'name', {
					writable: true,
					value: self.cleaned_filename(this.files[0].name)
				});
				key_field = $.grep(data, (n) => {
					if (n.name === 'key') {
						return n;
					}
				});

				if (key_field.length > 0) {
					key_field[0].value = self.settings.path + key;
				}

				if (!('FormData' in window)) {
					self.els.$upload_form.find('input[name="key"]').val(self.settings.path + key);
				}

				return data;
			}
		});
	}
};
